import React from "react";
import { useAppContext } from "./useAppContext";
import styled from "styled-components";
import uuid from "uuid";

const Button = styled.button`
  margin-left: 10px;
`;

export default () => {
  const { createTeam } = useAppContext();

  const onClick = () => {
    const input = prompt("New Team: ");
    if (input != null) {
      createTeam({
        id: uuid.v4(),
        createdAt: new Date(),
        title: input
      });
    }
  };
  return <Button onClick={onClick}>New Team</Button>;
};
