import React from "react";
import { useAppContext } from "./useAppContext";
import styled from "styled-components";

const Container = styled.div``;
const Label = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: gray;
`;

export default props => {
  const { state } = useAppContext();
  return (
    <Container>
      <Label>MILESTONE</Label>
      <select
        disabled={props.disabled}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      >
        {props.includeNone && <option value="">None</option>}
        {props.includeAll && <option value="all">All</option>}
        {(props.includeAll || props.includeNone) && (
          <option disabled>──────────</option>
        )}
        {state.db.milestones.map((f, idx) => (
          <option key={idx} value={f.id}>
            {f.title}
          </option>
        ))}
      </select>
    </Container>
  );
};
