import uuid from "uuid";

const LOCAL_STORAGE_KEY = "abubaca-db";

export function getFromLocalStorage(key) {
  const json = localStorage.getItem(getKey(key));
  const issues = json
    ? JSON.parse(json)
    : {
        issues: [],
        nextIssueSeq: 1,
        channels: [
          {
            id: uuid.v4(),
            rank: 10,
            title: "Backlog",
            milestoneId: "all",
            teamId: "all",
            status: "backlog",
            assigneeId: "none"
          },
          {
            id: uuid.v4(),
            rank: 20,
            title: "Todo",
            milestoneId: "all",
            teamId: "all",
            status: "todo",
            assigneeId: "none"
          },
          {
            id: uuid.v4(),
            rank: 30,
            title: "In Progress",
            milestoneId: "all",
            teamId: "all",
            status: "in-progress",
            assigneeId: "none"
          },
          {
            id: uuid.v4(),
            rank: 40,
            title: "In Test",
            milestoneId: "all",
            teamId: "all",
            status: "in-test",
            assigneeId: "none"
          },
          {
            id: uuid.v4(),
            rank: 50,
            title: "Done",
            milestoneId: "all",
            teamId: "all",
            status: "done",
            assigneeId: "none"
          }
        ],
        milestones: [],
        teams: [],
        members: [],
        selectedChannel: "all",
        selectedMilestone: "all",
        selectedTeam: "all",
        selectedStatus: "all",
        selectedAssignee: "none"
      };
  return issues;
}

export function setToLocalStorage(key, issues) {
  localStorage.setItem(getKey(key), JSON.stringify(issues));
}

export function clearLocalStorage(key) {
  localStorage.removeItem(getKey(key));
}

function getKey(key) {
  return `${LOCAL_STORAGE_KEY}/${key}`;
}
