export function queryParser(state) {
  let filteredIssues = state.db.issues;

  filteredIssues = filteredIssues.filter(i => {
    let ok = true;
    if (state.db.selectedMilestone !== "all") {
      ok = i.milestoneId === state.db.selectedMilestone;
    }
    if (ok && state.db.selectedTeam !== "all") {
      ok = i.teamId === state.db.selectedTeam;
    }
    if (ok && state.db.selectedAssignee !== "none") {
      ok = i.assigneeId === state.db.selectedAssignee;
    }
    if (ok && state.db.selectedStatus !== "all") {
      if (state.db.selectedStatus === "all_except_done") {
        ok = i.status !== "done";
      } else {
        ok = i.status === state.db.selectedStatus;
      }
    }

    return ok;
  });

  return filteredIssues;
}
export function queryParser_search(projectKey, query, issues) {
  let filteredIssues = issues;
  const data = extractDataFromString(projectKey, query);

  filteredIssues = filteredIssues.filter(i => {
    let ok = true;
    if (data.issueSeqs.length > 0) {
      ok = data.issueSeqs.find(s => s === i.seq);
    }
    if (data.remainingText) {
      ok = i.title.includes(data.remainingText);
    }
    if (ok && data.props["status"] != null) {
      ok = i.status === data.props["status"];
    }
    if (ok && data.props["assignee"] != null) {
      ok = i.assignee === data.props["assignee"];
    }
    return ok;
  });

  return filteredIssues;
}

export function extractDataFromString(projectKey, str) {
  let data = {
    status: [],
    issueSeqs: [],
    props: [],
    remainingText: str || ""
  };
  if (str && str.length > 0) {
    var re = new RegExp("([\\w\\.@#]+)\\s*([ :-]\\s*([\\w\\.,-]+))?", "g");
    var match = re.exec(str);
    while (match != null) {
      var field = match[1].trim();
      var value =
        match.length > 2 && match[2] != null
          ? match[2].trim().replace(":", "")
          : null;
      //console.log("-- field: " + field);
      //console.log("-- value: " + value);
      //We want to remove the match from the remaining text if it's a key:value pair.
      if (value != null) {
        data.remainingText = data.remainingText.replace(field, "");
      }
      if (value != null) {
        data.remainingText = data.remainingText.replace(value, "");
      }
      data.remainingText = data.remainingText.replace(/:/g, "").trim();

      if (field === projectKey && value != null) {
        data.issueSeqs.push(parseInt(value.replace(/-/g, ""), 10));
      } else if (value) {
        if (value === "true") {
          value = true;
        } else if (value === "false") {
          value = false;
        }
        data.props[field] = value;
      }
      match = re.exec(str);
    }
  }

  return data;
}
