import React from "react";
import styled from "styled-components";
import { Route, Link, NavLink } from "react-router-dom";

const Container = styled.div``;
const TopHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: #202020;
  border-bottom: 3px solid black;
  padding: 5px;
  padding-right: 30px;
  font-weight: bold;

  & a {
    color: gray;
    text-decoration: none;
    font-size: 12px;
    margin-right: 10px;

    &.active {
      color: white;
    }
    &:hover {
      color: white;
    }
  }
`;

const Left = styled.div`
  & a {
    color: orange;
    font-weight: bold;
  }
`;
const Right = styled.div``;

export default ({ component: Component, ...rest }) => {
  const { key } = rest.computedMatch.params;
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Container>
          <TopHeader>
            <Left>
              <Link to={`/${key}/issues`}>ABUBACA</Link>
            </Left>
            <Right>
              <NavLink to={`/${key}/issues`}>ISSUES</NavLink>
              <NavLink to={`/${key}/milestones`}>MILESTONES</NavLink>
              <NavLink to={`/${key}/teams`}>TEAMS</NavLink>
              <NavLink to={`/${key}/people`}>PEOPLE</NavLink>
              <NavLink to={`/${key}/channels`}>CHANNELS</NavLink>
              <NavLink to={`/${key}/export`}>EXPORT</NavLink>
            </Right>
          </TopHeader>
          <Component {...matchProps} />
        </Container>
      )}
    />
  );
};
