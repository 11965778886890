import React from "react";
import styled from "styled-components";
import { useAppContext } from "./useAppContext";

const Container = styled.div`
  & * {
    text-decoration: none;
  }
`;

const MemberItem = styled.li`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: ${props => (props.active ? "whitesmoke" : "white")};

  &:hover {
    background-color: whitesmoke;
  }
`;

const Title = styled.div``;

export default ({ member }) => {
  const { removeMember, setMemberTitle } = useAppContext();

  const onEditClicked = () => {
    const title = prompt("Change member title:", member.title);
    if (title) {
      setMemberTitle(member.id, title);
    }
  };

  const onDeleteClicked = () => {
    const result = window.confirm(
      "Are you sure you want to delete this member?"
    );
    if (result) {
      removeMember(member.id);
    }
  };

  return (
    <Container>
      <MemberItem>
        <Title>{member.title}</Title>
        <button onClick={onEditClicked}>Edit</button>
        <button onClick={onDeleteClicked}>Delete</button>
      </MemberItem>
    </Container>
  );
};
