import React from "react";
import styled from "styled-components";
import { useAppContext } from "./useAppContext";

const Container = styled.div``;
const TextArea = styled.textarea`
  margin: 10px;
  padding: 20px;
  width: calc(100% - 70px);
  height: calc(100vh - 100px);
  resize: none;
  border: none;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
`;

export default () => {
  const { state } = useAppContext();
  return (
    <Container>
      <TextArea defaultValue={JSON.stringify(state, null, 2)} />
    </Container>
  );
};
