import React from "react";
import styled from "styled-components";
import { useAppContext } from "./useAppContext";
import ChannelItem from "./ChannelItem";
import NewChannelButton from "./NewChannelButton";

const ChannelsPage = styled.div``;

const List = styled.ul`
  list-style-type: none;
  padding: 10px;
`;

const Error = styled.div`
  cursor: pointer;
  padding: 20px;
  width: 100%;
  background-color: red;
  color: white;
  margin-bottom: 10px;
`;

const Container = styled.div``;

const Header = styled.div`
  margin-top: 20px;

  margin-bottom: 20px;

  & > * {
    margin-right: 10px;
  }
`;

export default () => {
  const { state, setErrorMessage } = useAppContext();

  const onErrorClick = () => {
    setErrorMessage("");
  };

  const orderedChannels = state.db.channels.sort((a, b) => a.rank - b.rank);

  return (
    <ChannelsPage>
      {state.errorMessage && (
        <Error onClick={onErrorClick}>{state.errorMessage}</Error>
      )}
      <Container>
        <Header>
          <NewChannelButton />
        </Header>
        <List>
          {orderedChannels.map((channel, index) => (
            <ChannelItem key={index} channel={channel} />
          ))}
        </List>
      </Container>
    </ChannelsPage>
  );
};
