import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  padding: 50px;
`;

export default () => (
  <Container>
    <Link to="/prj/issues">404 | Ooops!</Link>
  </Container>
);
