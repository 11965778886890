import React from "react";
import { Route, Redirect } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "./useAppContext";
import IssuesView from "./IssuesView";
import IssueDetail from "./IssueDetail";
import Sidebar from "./Sidebar";

const IssuesPage = styled.div``;

const Error = styled.div`
  cursor: pointer;
  padding: 20px;
  width: 100%;
  background-color: red;
  color: white;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 2fr auto;
`;

export default () => {
  const { state, setErrorMessage } = useAppContext();

  const onErrorClick = () => {
    setErrorMessage("");
  };

  return (
    <IssuesPage>
      {state.errorMessage && (
        <Error onClick={onErrorClick}>{state.errorMessage}</Error>
      )}
      <Container>
        <Sidebar />
        <IssuesView />
        <Route
          exact
          path="/:key/issue/:seq"
          render={props => {
            const issue = state.db.issues.find(
              i => i.seq === parseInt(props.match.params.seq, 10)
            );
            if (issue) {
              return <IssueDetail issue={issue} />;
            } else {
              return <Redirect to={`/${props.match.params.key}`} />;
            }
          }}
        />
      </Container>
    </IssuesPage>
  );
};
