import React from "react";
import styled from "styled-components";

const Container = styled.div``;
const Label = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: gray;
`;

export default props => {
  return (
    <Container>
      <Label>STATUS</Label>
      <select
        disabled={props.disabled}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      >
        {props.includeAll && <option value="all">All</option>}
        {props.includeAllExceptDone && (
          <option value="all_except_done">All except Done</option>
        )}
        {props.includeAll && <option disabled>──────────</option>}
        <option value="backlog">Backlog</option>
        <option value="todo">Todo</option>
        <option value="in-progress">In Progress</option>
        <option value="in-test">In Test</option>
        <option value="done">Done</option>
      </select>
    </Container>
  );
};
