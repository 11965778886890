import React from "react";
import * as AppDb from "./AppDb";
import { useAppContext } from "./useAppContext";
import useRouter from "use-react-router";
import { Switch } from "react-router-dom";
import IssuesPage from "./IssuesPage";
import MilestonesPage from "./MilestonesPage";
import DefaultLayout from "./DefaultLayout";
import TeamsPage from "./TeamsPage";
import MembersPage from "./MembersPage";
import { Redirect, Route } from "react-router-dom";
import ChannelsPage from "./ChannelsPage";
import ExportPage from "./ExportPage";

export default () => {
  const { match } = useRouter();
  const { state } = useAppContext();
  React.useEffect(() => {
    AppDb.setToLocalStorage(match.params.key, state.db);
  }, [match.params.key, state.db]);

  return (
    <Switch>
      <DefaultLayout path="/:key/export/" component={ExportPage} />
      <DefaultLayout path="/:key/channels/" component={ChannelsPage} />
      <DefaultLayout path="/:key/people/" component={MembersPage} />
      <DefaultLayout path="/:key/teams/" component={TeamsPage} />
      <DefaultLayout path="/:key/milestones/" component={MilestonesPage} />
      <DefaultLayout path="/:key/issue/:seq" component={IssuesPage} />
      <DefaultLayout path="/:key/issues" component={IssuesPage} />
      <Route
        exact
        path="/:key"
        render={() => <Redirect to={`/${match.params.key}/issues`} />}
      />
    </Switch>
  );
};
