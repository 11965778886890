import React from "react";
import { useAppContext } from "./useAppContext";
import styled from "styled-components";

const Container = styled.div``;
const Label = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: gray;
`;

export default props => {
  const { state } = useAppContext();
  return (
    <Container>
      <Label>ASSIGNEE</Label>
      <select
        disabled={props.disabled}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      >
        <option value="">None</option>
        <option disabled>──────────</option>
        {state.db.members.map((m, idx) => (
          <option key={idx} value={m.id}>
            {m.title}
          </option>
        ))}
      </select>
    </Container>
  );
};
