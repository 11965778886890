import React from "react";
import update from "react-addons-update";
import * as AppDb from "./AppDb";
import useReactRouter from "use-react-router";

const AppContext = React.createContext();

export function AppProvider(props) {
  const { match } = useReactRouter();

  const [state, dispatch] = React.useReducer(appReducer, {
    projectKey: match.params.key,
    db: AppDb.getFromLocalStorage(match.params.key),
    searchQuery: ""
  });
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext must be used within a AppProvider`);
  }
  const [state, dispatch] = context;

  const setErrorMessage = errorMessage => {
    dispatch({ type: "SET_ERROR_MESSAGE", payload: { errorMessage } });
  };

  const createIssue = issue => {
    dispatch({ type: "CREATE_ISSUE", payload: { issue } });
  };

  const removeIssue = id => {
    dispatch({ type: "REMOVE_ISSUE", payload: { id } });
  };

  const setIssueTitle = (id, title) => {
    dispatch({ type: "SET_ISSUE_TITLE", payload: { id, title } });
  };

  const setIssueDesc = (id, desc) => {
    dispatch({ type: "SET_ISSUE_DESC", payload: { id, desc } });
  };

  const setIssueStatus = (id, status) => {
    dispatch({ type: "SET_ISSUE_STATUS", payload: { id, status } });
  };

  const setIssueMilestone = (id, milestoneId) => {
    dispatch({
      type: "SET_ISSUE_MILESTONE",
      payload: { id, milestoneId }
    });
  };

  const setIssueTeam = (id, teamId) => {
    dispatch({
      type: "SET_ISSUE_TEAM",
      payload: { id, teamId }
    });
  };

  const setIssueAssignee = (id, assigneeId) => {
    dispatch({
      type: "SET_ISSUE_ASSIGNEE",
      payload: { id, assigneeId }
    });
  };

  const setSearchQuery = searchQuery => {
    dispatch({ type: "SET_SEARCH_QUERY", payload: { searchQuery } });
  };

  const setSelectedChannel = id => {
    dispatch({ type: "SET_SELECTED_CHANNEL", payload: { id } });
  };

  const setSelectedMilestone = id => {
    dispatch({ type: "SET_SELECTED_MILESTONE", payload: { id } });
  };

  const setSelectedTeam = id => {
    dispatch({ type: "SET_SELECTED_TEAM", payload: { id } });
  };

  const setSelectedAssignee = id => {
    dispatch({ type: "SET_SELECTED_ASSIGNEE", payload: { id } });
  };

  const setSelectedStatus = selectedStatus => {
    dispatch({ type: "SET_SELECTED_STATUS", payload: { selectedStatus } });
  };

  const createMilestone = milestone => {
    dispatch({ type: "CREATE_MILESTONE", payload: { milestone } });
  };

  const removeMilestone = id => {
    dispatch({ type: "REMOVE_MILESTONE", payload: { id } });
  };

  const setMilestoneTitle = (id, title) => {
    dispatch({ type: "SET_MILESTONE_TITLE", payload: { id, title } });
  };

  const createTeam = team => {
    dispatch({ type: "CREATE_TEAM", payload: { team } });
  };

  const removeTeam = id => {
    dispatch({ type: "REMOVE_TEAM", payload: { id } });
  };

  const setTeamTitle = (id, title) => {
    dispatch({ type: "SET_TEAM_TITLE", payload: { id, title } });
  };

  const createMember = member => {
    dispatch({ type: "CREATE_MEMBER", payload: { member } });
  };

  const removeMember = id => {
    dispatch({ type: "REMOVE_MEMBER", payload: { id } });
  };

  const setMemberTitle = (id, title) => {
    dispatch({ type: "SET_MEMBER_TITLE", payload: { id, title } });
  };

  const createChannel = channel => {
    dispatch({ type: "CREATE_CHANNEL", payload: { channel } });
  };

  const removeChannel = id => {
    dispatch({ type: "REMOVE_CHANNEL", payload: { id } });
  };

  const setChannelTitle = (id, title) => {
    dispatch({ type: "SET_CHANNEL_TITLE", payload: { id, title } });
  };

  const setChannelStatus = (id, status) => {
    dispatch({ type: "SET_CHANNEL_STATUS", payload: { id, status } });
  };

  const setChannelMilestone = (id, milestoneId) => {
    dispatch({
      type: "SET_CHANNEL_MILESTONE",
      payload: { id, milestoneId }
    });
  };

  const setChannelTeam = (id, teamId) => {
    dispatch({
      type: "SET_CHANNEL_TEAM",
      payload: { id, teamId }
    });
  };

  const setChannelAssignee = (id, assigneeId) => {
    dispatch({
      type: "SET_CHANNEL_ASSIGNEE",
      payload: { id, assigneeId }
    });
  };

  const setChannelRank = (id, rank) => {
    dispatch({
      type: "SET_CHANNEL_RANK",
      payload: { id, rank }
    });
  };

  return {
    state,
    dispatch,
    setErrorMessage,
    createIssue,
    removeIssue,
    setIssueTitle,
    setIssueDesc,
    setIssueStatus,
    setIssueAssignee,
    setIssueMilestone,
    setIssueTeam,
    setSearchQuery,
    setSelectedChannel,
    setSelectedMilestone,
    setSelectedTeam,
    setSelectedAssignee,
    setSelectedStatus,
    createMilestone,
    removeMilestone,
    setMilestoneTitle,
    createTeam,
    removeTeam,
    setTeamTitle,
    createMember,
    removeMember,
    setMemberTitle,
    createChannel,
    removeChannel,
    setChannelTitle,
    setChannelStatus,
    setChannelAssignee,
    setChannelMilestone,
    setChannelTeam,
    setChannelRank
  };
}

export function appReducer(state, action) {
  let newState = state;
  switch (action.type) {
    case "SET_ERROR_MESSAGE": {
      newState = update(state, {
        errorMessage: { $set: action.payload.errorMessage }
      });
      break;
    }

    case "CREATE_ISSUE": {
      newState = update(state, {
        db: {
          issues: { $push: [action.payload.issue] },
          nextIssueSeq: { $set: state.db.nextIssueSeq + 1 }
        }
      });
      break;
    }

    case "REMOVE_ISSUE": {
      const idx = state.db.issues.findIndex(i => i.id === action.payload.id);
      if (idx !== -1) {
        newState = update(state, {
          db: {
            issues: { $splice: [[idx, 1]] }
          }
        });
      } else {
        newState = update(state, {
          errorMessage: {
            $set: `Cannot find issue ${action.payload.id}`
          }
        });
      }
      break;
    }

    case "SET_ISSUE_TITLE": {
      newState = updateField(state, action, "issues", "title");
      break;
    }

    case "SET_ISSUE_DESC": {
      newState = updateField(state, action, "issues", "desc");
      break;
    }

    case "SET_ISSUE_STATUS": {
      newState = updateField(state, action, "issues", "status");
      break;
    }

    case "SET_ISSUE_MILESTONE": {
      newState = updateField(state, action, "issues", "milestoneId");
      break;
    }

    case "SET_ISSUE_TEAM": {
      newState = updateField(state, action, "issues", "teamId");
      break;
    }

    case "SET_ISSUE_ASSIGNEE": {
      newState = updateField(state, action, "issues", "assigneeId");
      break;
    }

    case "SET_SEARCH_QUERY": {
      newState = update(state, {
        searchQuery: { $set: action.payload.searchQuery }
      });
      break;
    }

    case "CREATE_CHANNEL": {
      newState = update(state, {
        db: {
          channels: { $push: [action.payload.channel] }
        }
      });
      break;
    }

    case "REMOVE_CHANNEL": {
      const idx = state.db.channels.findIndex(i => i.id === action.payload.id);
      if (idx !== -1) {
        newState = update(state, {
          db: {
            channels: { $splice: [[idx, 1]] }
          }
        });
      } else {
        newState = update(state, {
          errorMessage: {
            $set: `Cannot find channel ${action.payload.id}`
          }
        });
      }
      break;
    }

    case "SET_CHANNEL_TITLE": {
      newState = updateField(state, action, "channels", "title");
      break;
    }

    case "SET_SELECTED_STATUS": {
      newState = update(state, {
        db: {
          selectedStatus: { $set: action.payload.selectedStatus }
        }
      });
      break;
    }

    case "SET_SELECTED_CHANNEL": {
      if (action.payload.id === "all") {
        newState = update(state, {
          db: {
            selectedChannel: { $set: "all" },
            selectedMilestone: { $set: "all" },
            selectedTeam: { $set: "all" },
            selectedStatus: { $set: "all" },
            selectedAssignee: { $set: "none" }
          }
        });
      } else {
        const selectedChannel = state.db.channels.find(
          c => c.id === action.payload.id
        );
        if (selectedChannel != null) {
          newState = update(state, {
            db: {
              selectedChannel: { $set: action.payload.id },
              selectedMilestone: { $set: selectedChannel.milestoneId },
              selectedTeam: { $set: selectedChannel.teamId },
              selectedStatus: { $set: selectedChannel.status },
              selectedAssignee: { $set: selectedChannel.assigneeId }
            }
          });
        } else {
          newState = update(state, {
            errorMessage: {
              $set: `Cannot find channel ${action.payload.id}`
            }
          });
        }
      }
      break;
    }

    case "SET_SELECTED_MILESTONE": {
      if (action.payload.id === "all") {
        newState = update(state, {
          db: {
            selectedMilestone: { $set: "all" }
          }
        });
      } else {
        const selectedMilestone = state.db.milestones.find(
          m => m.id === action.payload.id
        );
        if (selectedMilestone != null) {
          newState = update(state, {
            db: {
              selectedMilestone: { $set: action.payload.id }
            }
          });
        } else {
          newState = update(state, {
            errorMessage: {
              $set: `Cannot find milestone ${action.payload.id}`
            }
          });
        }
      }
      break;
    }

    case "SET_SELECTED_TEAM": {
      if (action.payload.id === "all") {
        newState = update(state, {
          db: {
            selectedTeam: { $set: "all" }
          }
        });
      } else {
        const selectedTeam = state.db.teams.find(
          m => m.id === action.payload.id
        );
        if (selectedTeam != null) {
          newState = update(state, {
            db: {
              selectedTeam: { $set: action.payload.id }
            }
          });
        } else {
          newState = update(state, {
            errorMessage: {
              $set: `Cannot find team ${action.payload.id}`
            }
          });
        }
      }
      break;
    }

    case "SET_SELECTED_ASSIGNEE": {
      if (action.payload.id === "") {
        newState = update(state, {
          db: {
            selectedAssignee: { $set: "none" }
          }
        });
      } else {
        const selectedAssignee = state.db.members.find(
          m => m.id === action.payload.id
        );
        if (selectedAssignee != null) {
          newState = update(state, {
            db: {
              selectedAssignee: { $set: action.payload.id }
            }
          });
        } else {
          newState = update(state, {
            errorMessage: {
              $set: `Cannot find person ${action.payload.id}`
            }
          });
        }
      }
      break;
    }

    case "CREATE_MILESTONE": {
      newState = update(state, {
        db: {
          milestones: { $push: [action.payload.milestone] }
        }
      });
      break;
    }

    case "REMOVE_MILESTONE": {
      const idx = state.db.milestones.findIndex(
        m => m.id === action.payload.id
      );
      if (idx !== -1) {
        newState = update(state, {
          db: {
            milestones: { $splice: [[idx, 1]] }
          }
        });
      } else {
        newState = update(state, {
          errorMessage: {
            $set: `Cannot find milestone ${action.payload.id}`
          }
        });
      }
      break;
    }

    case "SET_MILESTONE_TITLE": {
      newState = updateField(state, action, "milestones", "title");
      break;
    }

    case "CREATE_TEAM": {
      newState = update(state, {
        db: {
          teams: { $push: [action.payload.team] }
        }
      });
      break;
    }

    case "REMOVE_TEAM": {
      const idx = state.db.teams.findIndex(m => m.id === action.payload.id);
      if (idx !== -1) {
        newState = update(state, {
          db: {
            teams: { $splice: [[idx, 1]] }
          }
        });
      } else {
        newState = update(state, {
          errorMessage: {
            $set: `Cannot find team ${action.payload.id}`
          }
        });
      }
      break;
    }

    case "SET_TEAM_TITLE": {
      newState = updateField(state, action, "teams", "title");
      break;
    }

    case "CREATE_MEMBER": {
      newState = update(state, {
        db: {
          members: { $push: [action.payload.member] }
        }
      });
      break;
    }

    case "REMOVE_MEMBER": {
      const idx = state.db.members.findIndex(m => m.id === action.payload.id);
      if (idx !== -1) {
        newState = update(state, {
          db: {
            members: { $splice: [[idx, 1]] }
          }
        });
      } else {
        newState = update(state, {
          errorMessage: {
            $set: `Cannot find person ${action.payload.id}`
          }
        });
      }
      break;
    }

    case "SET_MEMBER_TITLE": {
      newState = updateField(state, action, "members", "title");
      break;
    }

    case "SET_CHANNEL_STATUS": {
      newState = updateField(state, action, "channels", "status");
      break;
    }

    case "SET_CHANNEL_MILESTONE": {
      newState = updateField(state, action, "channels", "milestoneId");
      break;
    }

    case "SET_CHANNEL_TEAM": {
      newState = updateField(state, action, "channels", "teamId");
      break;
    }

    case "SET_CHANNEL_ASSIGNEE": {
      newState = updateField(state, action, "channels", "assigneeId");
      break;
    }

    case "SET_CHANNEL_RANK": {
      newState = updateField(state, action, "channels", "rank");
      break;
    }

    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
  console.log(`> Reducer state for ${action.type}:`, newState, action);
  return newState;
}

function updateField(state, action, type, field) {
  let newState = state;
  const idx = state.db[type].findIndex(i => i.id === action.payload.id);
  if (idx !== -1) {
    newState = update(state, {
      db: {
        [type]: {
          [idx]: {
            [field]: { $set: action.payload[field] },
            updatedAt: new Date()
          }
        }
      }
    });
  } else {
    newState = update(state, {
      errorMessage: {
        $set: `Cannot find issue ${action.payload.id}`
      }
    });
  }
  return newState;
}
