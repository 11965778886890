import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "./useAppContext";

const IssueDetail = styled.div`
  height: 100vh;
  background-color: whitesmoke;
  border-left: 1px solid lightgray;
  padding: 10px;
  width: 300px;
`;

const IssueHeader = styled.h1`
  display: flex;
  font-size: 20px;
`;

const Seq = styled.div`
  color: #afafaf;
  margin-right: 5px;
`;
const Title = styled.div``;
const Body = styled.div`
  & > * {
    margin-right: 5px;
    margin-top: 5px;
  }
`;

const Info = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: gray;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Heading = styled.div`
  font-size: 12px;
  color: gray;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const Description = styled.textarea`
  border: 1px solid lightgray;
  height: 500px;
  width: 290px;
  resize: vertical;
  font-size: 14px;
  background-color: white;
  padding: 5px;
`;

export default ({ issue }) => {
  const { state, setIssueTitle, setIssueDesc, removeIssue } = useAppContext();

  const onTitleClick = () => {
    const title = prompt("New Title:", issue.title);
    if (title != null) {
      setIssueTitle(issue.id, title);
    }
  };

  const onDeleteClicked = () => {
    const result = window.confirm(
      "Are you sure you want to delete this issue?"
    );
    if (result) {
      removeIssue(issue.id);
    }
  };

  const onDescChange = e => {
    setIssueDesc(issue.id, e.target.value);
  };

  console.log("BOOM:", issue.createdAt);

  return (
    <IssueDetail>
      <Link to={`/${state.projectKey}/issues`}>
        <CloseButton>x</CloseButton>
      </Link>
      <IssueHeader>
        <Seq>#{issue.seq}</Seq>
        <Title>{issue.title}</Title>
      </IssueHeader>
      <Body>
        <button onClick={onTitleClick}>Change Title</button>
        <button onClick={onDeleteClicked}>Delete Issue</button>
        <Info>
          <li>
            Created {issue.createdAt ? moment(issue.createdAt).fromNow() : "??"}
          </li>
        </Info>
        <Info>
          <li>
            Last updated{" "}
            {issue.updatedAt ? moment(issue.updatedAt).fromNow() : "??"}
          </li>
        </Info>
        <Heading>DESCRIPTION</Heading>
        <Description
          onChange={onDescChange}
          value={issue.desc || ""}
        ></Description>
      </Body>
    </IssueDetail>
  );
};
