import React from "react";
import styled from "styled-components";
import useReactRouter from "use-react-router";
import { useAppContext } from "./useAppContext";

const Container = styled.div`
  & * {
    text-decoration: none;
  }
`;

const MilestoneItem = styled.li`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: ${props => (props.active ? "whitesmoke" : "white")};

  &:hover {
    background-color: whitesmoke;
  }
`;

const Title = styled.div``;

export default ({ milestone }) => {
  const { removeMilestone, setMilestoneTitle } = useAppContext();
  const { match } = useReactRouter();

  const onEditClicked = () => {
    const title = prompt("Change milestone title:", milestone.title);
    if (title) {
      setMilestoneTitle(milestone.id, title);
    }
  };

  const onDeleteClicked = () => {
    const result = window.confirm(
      "Are you sure you want to delete this milestone?"
    );
    if (result) {
      removeMilestone(milestone.id);
    }
  };

  return (
    <Container>
      <MilestoneItem active={parseInt(match.params.seq, 10) === milestone.seq}>
        <Title>{milestone.title}</Title>
        <button onClick={onEditClicked}>Edit</button>
        <button onClick={onDeleteClicked}>Delete</button>
      </MilestoneItem>
    </Container>
  );
};
