import React from "react";
import { useAppContext } from "./useAppContext";
import styled from "styled-components";

const Container = styled.div`
  background-color: #0747a6;
  color: white;
  width: 200px;
  height: 100vh;
  border-right: 3px solid #043682;
`;
const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
const ListItem = styled.li`
  padding: 10px;
  background-color: ${({ active }) => (active ? "#043682" : "")};
  cursor: pointer;
  &:hover {
    background-color: #043682;
  }
`;

export default () => {
  const { state, setSelectedChannel } = useAppContext();
  const orderedChannels = state.db.channels.sort((a, b) => a.rank - b.rank);
  return (
    <Container>
      <List>
        <ListItem
          onClick={() => setSelectedChannel("all")}
          active={state.db.selectedChannel === "all"}
        >
          All
        </ListItem>
        {orderedChannels.map((c, idx) => (
          <ListItem
            active={c.id === state.db.selectedChannel}
            onClick={() => setSelectedChannel(c.id)}
            key={idx}
          >
            {c.title}
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
