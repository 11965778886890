import React from "react";
import styled from "styled-components";
import IssueItem from "./IssueItem";
import NewIssueButton from "./NewIssueButton";
import StatusSelect from "./StatusSelect";
import { useAppContext } from "./useAppContext";
import { queryParser } from "./queryParser";
import MilestoneSelect from "./MilestoneSelect";
import TeamSelect from "./TeamSelect";
import AssigneeSelect from "./AssigneeSelect";

const Container = styled.div``;

const List = styled.ul`
  list-style-type: none;
  padding: 10px;
`;

const Header = styled.div`
  background-color: whitesmoke;
  border-bottom: 1px solid lightgray;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;

  & > * {
    margin-right: 10px;
  }
`;

const EmptyMsg = styled.div`
  color: gray;
  font-style: italic;
  padding: 20px;
`;

export default () => {
  const {
    state,
    setSelectedMilestone,
    setSelectedTeam,
    setSelectedAssignee,
    setSelectedStatus
  } = useAppContext();

  const filteredIssues = queryParser(state);
  const disabled = state.db.selectedChannel !== "all";
  return (
    <Container>
      <Header>
        <MilestoneSelect
          value={state.db.selectedMilestone}
          onChange={value => setSelectedMilestone(value)}
          includeAll={true}
          disabled={disabled}
        />
        <TeamSelect
          value={state.db.selectedTeam}
          onChange={value => setSelectedTeam(value)}
          includeAll={true}
          disabled={disabled}
        />
        <AssigneeSelect
          value={state.db.selectedAssignee}
          onChange={value => setSelectedAssignee(value)}
          disabled={disabled}
        />
        <StatusSelect
          value={state.db.selectedStatus}
          onChange={value => setSelectedStatus(value)}
          includeAll={true}
          includeAllExceptDone={true}
          disabled={disabled}
        />
        <NewIssueButton />
      </Header>
      <List>
        {filteredIssues.map((issue, index) => (
          <IssueItem key={index} issue={issue} />
        ))}
        {filteredIssues.length === 0 && <EmptyMsg>No issues found</EmptyMsg>}
      </List>
    </Container>
  );
};
