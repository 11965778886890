import React from "react";
import styled from "styled-components";
import { useAppContext } from "./useAppContext";
import MilestoneItem from "./MilestoneItem";
import NewMilestoneButton from "./NewMilestoneButton";

const MilestonesPage = styled.div``;

const List = styled.ul`
  list-style-type: none;
  padding: 10px;
`;

const Error = styled.div`
  cursor: pointer;
  padding: 20px;
  width: 100%;
  background-color: red;
  color: white;
  margin-bottom: 10px;
`;

const Container = styled.div``;

const Header = styled.div`
  margin-top: 20px;

  margin-bottom: 20px;

  & > * {
    margin-right: 10px;
  }
`;

export default () => {
  const { state, setErrorMessage } = useAppContext();

  const onErrorClick = () => {
    setErrorMessage("");
  };

  return (
    <MilestonesPage>
      {state.errorMessage && (
        <Error onClick={onErrorClick}>{state.errorMessage}</Error>
      )}
      <Container>
        <Header>
          <NewMilestoneButton />
        </Header>
        <List>
          {state.db.milestones.map((milestone, index) => (
            <MilestoneItem key={index} milestone={milestone} />
          ))}
        </List>
      </Container>
    </MilestonesPage>
  );
};
