import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAppContext } from "./useAppContext";
import useReactRouter from "use-react-router";
import MilestoneSelect from "./MilestoneSelect";
import StatusSelect from "./StatusSelect";
import TeamSelect from "./TeamSelect";
import AssigneeSelect from "./AssigneeSelect";

const Container = styled.div`
  & * {
    text-decoration: none;
  }
`;

const IssueItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto auto;
  grid-column-gap: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: ${props => (props.active ? "whitesmoke" : "white")};

  &:hover {
    background-color: whitesmoke;
  }
`;

const Seq = styled.div`
  color: #afafaf;
`;
const Title = styled.div``;

export default ({ issue }) => {
  const { match } = useReactRouter();
  const {
    state,
    setIssueMilestone,
    setIssueTeam,
    setIssueAssignee,
    setIssueStatus
  } = useAppContext();
  return (
    <Container>
      <IssueItem active={parseInt(match.params.seq, 10) === issue.seq}>
        <Seq>#{issue.seq}</Seq>
        <Link to={`/${state.projectKey}/issue/${issue.seq}`}>
          <Title>{issue.title}</Title>
        </Link>

        <MilestoneSelect
          value={issue.milestoneId}
          onChange={milestoneId => setIssueMilestone(issue.id, milestoneId)}
          includeNone={true}
        />

        <TeamSelect
          value={issue.teamId}
          onChange={teamId => setIssueTeam(issue.id, teamId)}
          includeNone={true}
        />

        <AssigneeSelect
          value={issue.assigneeId}
          onChange={assigneeId => setIssueAssignee(issue.id, assigneeId)}
        />

        <StatusSelect
          value={issue.status}
          onChange={status => setIssueStatus(issue.id, status)}
        />
      </IssueItem>
    </Container>
  );
};
