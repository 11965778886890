import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import NotFound from "./NotFound";
import { AppProvider } from "./useAppContext";
import ProjectPage from "./ProjectPage";

export default () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/:key"
          render={() => (
            <AppProvider>
              <ProjectPage />
            </AppProvider>
          )}
        />
        <Route exact path="/" render={() => <Redirect to="/prj/issues" />} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};
