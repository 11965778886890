import React from "react";
import styled from "styled-components";
import { useAppContext } from "./useAppContext";
import MilestoneSelect from "./MilestoneSelect";

import StatusSelect from "./StatusSelect";
import TeamSelect from "./TeamSelect";
import AssigneeSelect from "./AssigneeSelect";

const Container = styled.div`
  & * {
    text-decoration: none;
  }
`;

const ChannelItem = styled.li`
  display: grid;
  grid-template-columns: 1fr auto auto auto auto auto auto auto;
  grid-column-gap: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: ${props => (props.active ? "whitesmoke" : "white")};

  &:hover {
    background-color: whitesmoke;
  }
`;

const Title = styled.div``;

export default ({ channel }) => {
  const {
    removeChannel,
    setChannelTitle,
    setChannelMilestone,
    setChannelTeam,
    setChannelAssignee,
    setChannelStatus,
    setChannelRank
  } = useAppContext();

  const onEditClicked = () => {
    const title = prompt("Change channel title:", channel.title);
    if (title) {
      setChannelTitle(channel.id, title);
    }
  };

  const onDeleteClicked = () => {
    const result = window.confirm(
      "Are you sure you want to delete this channel?"
    );
    if (result) {
      removeChannel(channel.id);
    }
  };

  const onRankClicked = () => {
    let rank = prompt("Change channel rank:", channel.rank);
    if (rank) {
      rank = parseInt(rank, 10);
      setChannelRank(channel.id, rank);
    }
  };

  return (
    <Container>
      <ChannelItem>
        <Title>{channel.title}</Title>
        <MilestoneSelect
          value={channel.milestoneId}
          onChange={milestoneId => setChannelMilestone(channel.id, milestoneId)}
          includeAll={true}
        />

        <TeamSelect
          value={channel.teamId}
          onChange={teamId => setChannelTeam(channel.id, teamId)}
          includeAll={true}
        />

        <AssigneeSelect
          value={channel.assigneeId}
          onChange={assigneeId => setChannelAssignee(channel.id, assigneeId)}
        />

        <StatusSelect
          value={channel.status}
          onChange={status => setChannelStatus(channel.id, status)}
          includeAll={true}
        />
        <button onClick={onRankClicked}>Rank: {channel.rank || "??"}</button>
        <button onClick={onEditClicked}>Edit Title</button>
        <button onClick={onDeleteClicked}>Delete</button>
      </ChannelItem>
    </Container>
  );
};
