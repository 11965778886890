import React from "react";
import { useAppContext } from "./useAppContext";
import styled from "styled-components";
import uuid from "uuid";

const Button = styled.button`
  margin-left: 10px;
`;

export default () => {
  const { state, createChannel, setSelectedChannel } = useAppContext();

  const onClick = () => {
    const input = prompt("Channel Name: ");
    if (input != null) {
      const channel = {
        id: uuid.v4(),
        createdAt: new Date(),
        rank: (state.db.channels.length + 1) * 10,
        title: input,
        milestoneId: "all",
        teamId: "all",
        status: "all",
        assigneeId: "none"
      };
      createChannel(channel);
      setSelectedChannel(channel.id);
    }
  };
  return <Button onClick={onClick}>New Channel</Button>;
};
