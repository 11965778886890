import React from "react";
import { useAppContext } from "./useAppContext";
import styled from "styled-components";
import uuid from "uuid";

const Button = styled.button`
  margin-left: 10px;
`;

export default () => {
  const { state, createIssue } = useAppContext();
  const onClick = () => {
    const input = prompt("New Issue: ");
    if (input != null) {
      const date = new Date();
      createIssue({
        id: uuid.v4(),
        createdAt: date,
        updatedAt: date,
        seq: state.db.nextIssueSeq,
        title: input,
        status: state.db.selectedStatus.includes("all")
          ? "backlog"
          : state.db.selectedStatus,
        milestoneId:
          state.db.selectedMilestone === "all"
            ? ""
            : state.db.selectedMilestone,
        teamId: state.db.selectedTeam === "all" ? "" : state.db.selectedTeam,
        assigneeId:
          state.db.selectedAssignee === "none" ? "" : state.db.selectedAssignee
      });
    }
  };
  return <Button onClick={onClick}>New Issue</Button>;
};
